import { Container, Button, Image } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import logo from '../img/logo.svg'

function InfoOverlay({show, setInfoShow, setIsMotion}) {
    return(
    <Modal
      show={show}
      fullscreen={true}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className='text-white'
    >
        <Modal.Body className='bg-brand'>
            <Container fluid className='position-absolute top-0 start-0 toFront py-2'>
                <Image src={logo} className='d-block mx-auto title-img-no-anim' alt="MATTHIEU LIVINGSTON"/>
            </Container>
            <Container fluid className='nav-button-container toFront py-2'>
                <Button variant="link" className="fs-2 text-uppercase text-white custom-link" onClick={() => {setIsMotion(true); setInfoShow(false)}}>MOVING IMAGE</Button>
                <Button variant="link" className="fs-2 text-uppercase text-white custom-link float-end" onClick={() => {setIsMotion(false); setInfoShow(false)}}>PHOTOGRAPHY</Button>
            </Container>
            <Container fluid className='position-absolute translate-middle-y toFront info-cont'>
                <Button variant="link" className="fs-2 text-uppercase text-white custom-link py-0 px-0 info-btn" onClick={() => setInfoShow(false)}>CLOSE</Button>
            </Container>
            <Container className='middle-text info-width'>
                <p>Matthieu Livingston, director and photographer, is recognised for his compelling narrative and classic artistic approach. Influenced by his French/Egyptian heritage, Matthieu brings a diverse taste to his work that sets him apart. 
                He has collaborated with notable clients such as IWC, Giorgio Armani, Thom Sweeney, Trunk, A Collected Man, Rubato, and Knight Frank, showcasing his versatility and refined style. 
                Matthieu's work has been featured in respected publications like GQ, HypeBeast, Hodinkee, and The View. 
                Additionally, his films have been acknowledged at reputable festivals such as the London Film Festival (BAFTA Recognized), Shorts on Tap, and the Winchester Short Film Festival. 
                His studio, Blown Away Studios, is located on 78 Luke Street, London, EC2A 4PY.
                <br/><br/>
                Email: matt@blownawaystudios.co.uk Instagram: @matthieu_livingston
                </p>
            </Container>
        </Modal.Body>
    </Modal>)
}

export default InfoOverlay;