import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';

function NavButtons({setIsMotion, setInfoShow}){
    return(
        <>
        <Container fluid className='nav-button-container toFront'>
            <Button variant="link" className="fs-2 text-uppercase text-white custom-link custom-fade" onClick={() => setIsMotion(true)}>MOVING IMAGE</Button>
            <Button variant="link" className="fs-2 text-uppercase text-white custom-link float-end custom-fade" onClick={() => setIsMotion(false)}>PHOTOGRAPHY</Button>
        </Container>
        <Container fluid className='position-absolute translate-middle-y toFront info-cont'>
        <Button variant="link" className="fs-2 text-uppercase text-white custom-link py-0 px-0 info-btn custom-fade" onClick={() => setInfoShow(true)}>INFO</Button>
        </Container>
        </>
    );
}

export default NavButtons;