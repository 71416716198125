import { useRef, useEffect } from "react";

export function useHorizontalScroll() {
    const elRef = useRef();
    useEffect(() => {
      const el = elRef.current;
      if (el) {
        const onWheel = e => {
          if (e.deltaY === 0) return;
          e.preventDefault();
          el.scrollBy({
            left: e.deltaY,
            top: 0,
            behaviour: "smooth"
          });
          /*el.scrollTo({
            left: el.scrollLeft + e.deltaY,
            behavior: "auto"
          });*/
        };
        el.addEventListener("wheel", onWheel);
        return () => el.removeEventListener("wheel", onWheel);
      }
    }, []);
    return elRef;
}