import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import { useState, useEffect, useRef } from 'react';
import { useHorizontalScroll } from './NavScroll';



function CustomNav({isMotion, imageProjects, videoProjects, activeIndex, swiper}){
  const imageItemsRef = useRef([]);
  const videoItemsRef = useRef([]);
  const [activeArray, setActiveArray] = useState([]);
  const scrollRef = useHorizontalScroll();

  useEffect(() => {
    setActiveArray([]);
    for (let i = 0; i < imageProjects.length; i++){
      for (let c = 0; c < imageProjects[i].images.length; c++){
        setActiveArray(activeArray => [...activeArray, i]);
      }
    }
  }, [imageProjects]);

  useEffect(() =>{
    if(isMotion){
      videoItemsRef.current[activeIndex]?.scrollIntoView({behaviour: "smooth", block: 'nearest', inline: 'nearest'});
    } else {
      imageItemsRef.current[activeArray[activeIndex]]?.scrollIntoView({behaviour: "smooth", block: 'nearest', inline: 'nearest'});
    }
    
  }, [activeIndex, activeArray, isMotion]);

  const imageLinks = imageProjects.map((imageData, i) => <Nav.Link 
    key={i}
    active={activeArray[activeIndex] === i}
    onClick={() => {swiper.slideTo(activeArray.indexOf(i)); imageItemsRef.current[i].scrollIntoView({behaviour: "smooth", block: 'nearest', inline: 'nearest'})}}
    ref={el => imageItemsRef.current[i] = el}
    className='fs-5 px-2-5'>
      {imageData.title}
      </Nav.Link>);

  
  const videoLinks = videoProjects.map((videoData, i) => 
    <Nav.Link key={i} active={i === activeIndex} ref={el => videoItemsRef.current[i] = el} onClick={() => 
      {
        swiper.slideTo(i);
        videoItemsRef.current[i].scrollIntoView({behaviour: "smooth", block: 'nearest', inline: 'nearest'})
      }} className='fs-5 px-2-5'>{videoData.title}</Nav.Link>
  );

    return(
        <Navbar data-bs-theme="dark" fixed="bottom" className='custom-nav text-uppercase'>
        <Container fluid>
          <Nav className="me-auto" ref={scrollRef}>
            {isMotion ? videoLinks : imageLinks}
          </Nav>
        </Container>
      </Navbar>
    );
}


export default CustomNav