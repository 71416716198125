import LazyLoad from 'react-lazy-load';
import { useRef, useEffect } from 'react';
import { isSafari, isMobileSafari } from 'react-device-detect';

function LazyVideo({ mobSrc, src, isMobile, swiper }){
  const videoRef = useRef();

  useEffect(() => {    
    videoRef.current?.load();
  }, [src, mobSrc, isMobile]);

  return(
    <LazyLoad>
      <video className='is-a-clip' playsInline autoPlay muted src={isMobile ? mobSrc : src} type="video/mp4" 
      onEnded={() => {
        if (!isSafari && !isMobileSafari){
        videoRef.current.currentTime = 0; 
        swiper.slideNext();}
      }} ref={videoRef} loop={isSafari || isMobileSafari ? true : false}>
        Your browser does not support the video tag.
      </video>
    </LazyLoad>
  );
}


export default LazyVideo;