import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import logo from '../img/logo.svg'

function Name({setisFinished}){
    return(
        <Container fluid className='position-absolute top-0 start-0 toFront gradient-bg py-2'>
            <Image src={logo} className='title-img d-block mx-auto' onAnimationEnd={() => setisFinished(true)} alt="MATTHIEU LIVINGSTON"/>
        </Container>
    );
}

export default Name;