import './App.css';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { useState, useEffect } from 'react';
import LazyLoad from 'react-lazy-load';
import Name from './components/Name'
import NavButtons from './components/NavButtons';
import VideoOverlay from './components/VideoOverlay';
import CustomNav from './components/CustomNav';
import projects from './projects/projects.json';
import LazyVideo from './components/LazyVideo';
import InfoOverlay from './components/InfoOverlay';
import { isSafari, isMobileSafari } from 'react-device-detect';


function App() {
  const [isFinished, setisFinished] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [infoShow, setInfoShow] = useState(false);
  const [modalUri, setModalUri] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [swiper, setSwiper] = useState();
  const [isMotion, setIsMotion] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const [switchy, setSwitchy] = useState(true);
  const [performLogic, setPerformLogic] = useState(true);
  const [abslastDeltaY, setAbslastDeltaY] = useState(0);
  const [abslastDeltaX, setAbslastDeltaX] = useState(0);
  const [timerTimeout, setTimerTimeout] = useState(null);
  const [lastPromise, setLastPromise] = useState();
  
  const imageProjects = projects.imageprojects;
  const imageSlides = imageProjects.map((imageData) => imageData.images.map((subImage, i) => 
    <SwiperSlide key={"img-" + i} onClick={() => swiper.slideNext()} >
      <LazyLoad>
        <img src={subImage} alt={imageData.title}/>
      </LazyLoad>
    </SwiperSlide>));

  const videoProjects = projects.videoprojects;
  const videoSlides = videoProjects.map((videoData, i) => 
    <SwiperSlide key={"vidproj-" + i} onClick={() => {setModalTitle(videoData.title); setModalUri(videoData.full); setModalShow(true)}} className='bg-black'>
          <LazyVideo key={"videos-" + i} mobSrc={videoData?.mobile} src={videoData?.clip} isMobile={isMobile} swiper={swiper} />
    </SwiperSlide>
  );


  useEffect(() => {
    const scrollLogic = (deltaX, deltaY) => {
      const absdeltaX = Math.abs(deltaX);
      const absdeltaY = Math.abs(deltaY);
      if (absdeltaX > absdeltaY) {
        if (deltaX > 0){
          swiper.slideNext();
        } else {
          swiper.slidePrev();
        }
      } else {
        if (deltaY > 0){
          swiper.slideNext();
        } else {
          swiper.slidePrev();
        }
      }
    }

    const handleScroll = (event) => {
      if (event.target.classList.contains('nav-link')) {
        return;
      }

      const deltaX = Math.abs(event.deltaX);
      const deltaY = Math.abs(event.deltaY);

      if (deltaX > 20 || deltaY > 20){
        if (deltaX > abslastDeltaX || deltaY > abslastDeltaY){
          setSwitchy(true);
        } else if ((deltaX < abslastDeltaX || deltaY < abslastDeltaY) && switchy){
          if (performLogic){
            scrollLogic(event.deltaX, event.deltaY);
            setPerformLogic(false);
            clearTimeout(timerTimeout);
          }
          const newTimerTimeout = setTimeout(() => {
            setSwitchy(false);
            setPerformLogic(true);
          }, 500);
          setTimerTimeout(newTimerTimeout)
        } 
        setAbslastDeltaX(deltaX);
        setAbslastDeltaY(deltaY);
      }
    };
    window.addEventListener('wheel', handleScroll);
    return () => {
      window.removeEventListener('wheel', handleScroll);
      clearTimeout(timerTimeout);
    };
  }, [abslastDeltaY, abslastDeltaX, switchy, performLogic, timerTimeout, swiper]);
 
  const handleResize = () => {
    if (window.innerWidth < 1024 && window.innerHeight < 500) {
      setisFinished(true);
      setIsMobile(false);
    } else if (window.innerWidth < 1024) {
      setisFinished(true);
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }

  // create an event listener
  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
  }, [isFinished])

  return (
    <div className="App">
      <Name setisFinished={setisFinished}/>
      {isFinished === true && <NavButtons setIsMotion={setIsMotion} setInfoShow={setInfoShow}/>}
      <VideoOverlay show={modalShow} onHide={() => setModalShow(false)} heading={modalTitle} uri={modalUri} />
      <InfoOverlay show={infoShow} setIsMotion={setIsMotion} setInfoShow={setInfoShow} onHide={() => setInfoShow(false)} />
      <Swiper
      spaceBetween={0}
      slidesPerView={1}
      onActiveIndexChange={(swiper) => {
        setActiveIndex(swiper.activeIndex);
      }}
      onSwiper={(swiper) => setSwiper(swiper)}
      onTransitionStart={() => {
        if (!isSafari && !isMobileSafari){
        try {
          const videos = document.querySelectorAll('video.is-a-clip'); 
          if (videos){
            videos.forEach(video => {
              if (lastPromise){
                lastPromise.then(() => {
                  let promise = video.pause();
                  setLastPromise(promise);
              })
              } else {
                let promise = video.pause();
                setLastPromise(promise);
              }
            });
          }
        } catch(error) {
          console.log(error);
        }}
      }}
      onTransitionEnd={() => {
      if (!isSafari && !isMobileSafari){
      try {
        const activeSlide = document.getElementsByClassName('swiper-slide')[activeIndex];
        if (activeSlide){
          const activeSlideVideo = activeSlide.getElementsByTagName('video')[0];
          if (activeSlideVideo && swiper.isEnd) {
            if (lastPromise){
              lastPromise.then(() => {
                let promise = activeSlideVideo.play();
                setLastPromise(promise);
                activeSlideVideo.loop = true;
              });
            } else {
              let promise = activeSlideVideo.play();
              setLastPromise(promise);
              activeSlideVideo.loop = true;
            }
          } else if (activeSlideVideo){
            if (lastPromise){
              lastPromise.then(() => {
                let promise = activeSlideVideo.play();
                setLastPromise(promise);    
              });
            } else {
              let promise = activeSlideVideo.play();
              setLastPromise(promise);  
            }
          }
        }
      } catch(error) {
        console.log(error);
      }
      }
      }}
      >
          {isMotion ? videoSlides : imageSlides}
      </Swiper>
      <CustomNav isMotion={isMotion} imageProjects={imageProjects} videoProjects={videoProjects} activeIndex={activeIndex} swiper={swiper}/>
    </div>
  );
}

export default App;