import { Container } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';


function VideoOverlay(props) {
    return(
    <Modal
      {...props}
      fullscreen={true}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className='text-white'
    >
        <Modal.Header closeButton closeVariant='white' className='bg-brand px-4 py-4 border-0'>
        </Modal.Header>
        <Modal.Body className='bg-brand'>
            <Container>
                <h1 className='text-uppercase fs-5'>{props.heading}</h1>
                {props.uri !== "" ? 
                <video width="100%" height="80%" controls autoPlay className='vidover'>
                <source src={props.uri} type="video/mp4" />
                </video>
                :
                <p className='text-uppercase'>Full Video Coming Soon</p>
                }
                
            </Container>
        </Modal.Body>
    </Modal>)
}

export default VideoOverlay;